import { api } from '~/data/api'
import { IUser } from '~/utils/types'

export interface IUpdateAccountProps {
  userId?: string
  data?: IUser
}

export async function updateAccount({ userId, data }: IUpdateAccountProps) {
  await api().patch(`/user/${userId}`, data)
}

interface IDocument {
  name: string
  file: File | null
}

export interface IUpdateUserDocument {
  userId?: string
  document: IDocument
}

export async function updateUserDocument({ userId, document }: IUpdateUserDocument) {
  const formData = new FormData()
  formData.append(document.name, document.file as any)

  await api().put(`/user/import_document/${document.name}?user_id=${userId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export interface IGetUserDocument {
  userId?: string
  document_key?: string | null
}

export async function getUserDocument({ userId, document_key }: IGetUserDocument) {
  return await api().get(`/user/document?user_id=${userId}&file_key=${document_key}`)
}

export interface IDeleteUserDocument {
  userId?: string
  document_key?: string | null
}

export async function deleteUserDocument({ userId, document_key }: IDeleteUserDocument) {
  return await api().delete(`/user/document?user_id=${userId}&file_key=${document_key}`)
}

export interface IGetUserProfilePicture {
  userId: string
}

export async function getUserProfilePicture(props: IGetUserProfilePicture): Promise<string> {
  const response = await api().get(`/user/${props.userId}/profile_picture`)
  return response.data || ''
}

export interface IUpdateUserProfilePicture {
  userId: string
  image: File | null
}

export async function updateUserProfilePicture(props: IUpdateUserProfilePicture): Promise<string> {
  const formData = new FormData()
  if (props.image) formData.append('image', props.image)

  const response = await api().post(`/user/${props.userId}/profile_picture`, formData)
  return response.data || ''
}
