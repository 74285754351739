import { api } from '~/data/api'
import {
  IAircraftInspectionHistory,
  IInspection,
  IInspectionInvitation,
  INewReportExport,
  IOperatorFeedback,
  IReportExport,
  IReportFile,
  IUser,
  TInvitationLevel,
  TReportExportMode,
} from '~/utils/types'

export const getAircraftHistoryByAircraftId = async (
  aircraftId: string,
): Promise<IAircraftInspectionHistory> => {
  const response = await api().get(`/aircraft_history/find_by_aircraft_id/${aircraftId}`)
  return response.data
}

export const getInspectionById = async (inspectionId: string): Promise<IInspection> => {
  const response = await api().get(`inspection/${inspectionId}?last_inspection=true`)
  return response.data
}

export const getReportExportsById = async (reportId: string): Promise<IReportExport[]> => {
  const response = await api().get(`/report/${reportId}/exports`)
  return response.data
}

interface IExportUrl {
  file_url: string
}

export const generateReportExportById = async (
  reportId: string,
  mode: TReportExportMode = 'lean',
): Promise<IExportUrl> => {
  const response = await api().get(`/report/${reportId}/export`, {
    params: {
      mode,
    },
  })
  return response.data
}

export const getReportExportDownload = async (
  reportId: string,
  exportFile: string,
): Promise<IExportUrl> => {
  const response = await api().get(`/report/${reportId}/exports/${exportFile}`)

  return response.data
}

export interface IInspectionReportFileURL {
  author: IUser
  file_path: string
  file_name: string
  date: string
}

export const exportReportToDocx = async (reportId: string, templateId: string) => {
  const response = await api().post(`report_export/${reportId}`, {
    report_template: templateId,
  })

  return response.data
}

export interface IListAircraftInspectionHistory {
  lean?: boolean
  lastInspection?: boolean
}

export async function listAircraftInspectionHistory({
  lean = true,
  lastInspection = true,
}: IListAircraftInspectionHistory): Promise<IAircraftInspectionHistory[]> {
  const response = await api().get('/aircraft_history', {
    params: {
      lean: lean === true,
      last_inspection: lastInspection == true,
    },
  })

  return response.data as IAircraftInspectionHistory[]
}

export const getOperatorFeedbackById = async (id: string): Promise<IOperatorFeedback> => {
  const response = await api().get(`/operator_feedback/${id}`)
  return response.data
}

export const getOperatorFeedbackByDiscrepancyId = async (discrepancyId?: string) => {
  const response = await api().get(`/operator_feedback/find_by_discrepancy/${discrepancyId}`)
  return response.data
}
export const createOperatorFeedback = async (
  operatorFeedback: IOperatorFeedback,
): Promise<IOperatorFeedback> => {
  const response = await api().post(`/operator_feedback`, operatorFeedback)
  return response.data as IOperatorFeedback
}

export interface IUpdateOperatorFeedbackParams {
  operatorFeedbackId: string
  payload: Partial<IOperatorFeedback>
}

export const updateOperatorFeedback = async (params: IUpdateOperatorFeedbackParams) => {
  const response = await api().patch(
    `operator_feedback/${params.operatorFeedbackId}`,
    params.payload,
  )
  return response.data as IOperatorFeedback
}

export const getInspectionReportFiles = async (inspectionId?: string): Promise<IReportFile[]> => {
  const response = await api().get(`inspection/${inspectionId}/report_files`)
  return response.data as IReportFile[]
}

export const getReportExports = async (reportId: string): Promise<INewReportExport[]> => {
  const response = await api().get(`report_export/${reportId}`)

  return response.data as INewReportExport[]
}

export const getReportExportUrl = async (
  reportId: string,
  reportExportId: string,
): Promise<string> => {
  const response = await api().get(`report_export/${reportId}/${reportExportId}/download`)

  return response.data as string
}

export const deleteReportExport = async (
  reportId: string,
  reportExportId: string,
): Promise<INewReportExport> => {
  const response = await api().delete(`report_export/${reportId}/${reportExportId}`)

  return response.data as INewReportExport
}

export const getOperatorFeedbackFiles = async (findingId?: string): Promise<IReportFile[]> => {
  const response = await api().get(`inspection/${findingId}/report_files`)
  return response.data as IReportFile[]
}

interface IUploadInspectionFiles {
  inspectionId: string
  files: Blob[]
}

interface IUploadOperatorFeedbackFiles {
  findindId: string
  files: Blob[]
}

export const uploadInspectionFiles = async ({ inspectionId, files }: IUploadInspectionFiles) => {
  const formData = new FormData()
  files.forEach((_file, index) => formData.append('files', files[index]))

  return await api().post(`/inspection/${inspectionId}/upload_files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const uploadOperatorFeedback = async ({
  findindId,
  files,
}: IUploadOperatorFeedbackFiles) => {
  const formData = new FormData()
  files.forEach((_file, index) => formData.append('files', files[index]))

  return await api().post(`/inspection/${findindId}/upload_evidences`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
interface IRemoveInspectionFile {
  inspectionId: string
  fileName: string
}

interface IRemoveOperatorFeedbackFile {
  findingId: string
  fileName: string
}

export const removeInspectionFile = async ({ inspectionId, fileName }: IRemoveInspectionFile) => {
  return await api().delete(`/inspection/${inspectionId}/report_files`, {
    data: {
      file_name: fileName,
    },
  })
}

export const removeOperatorFeedbackFile = async ({
  findingId,
  fileName,
}: IRemoveOperatorFeedbackFile) => {
  return await api().delete(`/operator_feedback/${findingId}/report_files`, {
    data: {
      file_name: fileName,
    },
  })
}
interface IDownloadInspectionFile {
  inspectionId: string
  fileName: string
}

interface IDownloadZipFile {
  reportId: string
  dataType: string
}

export interface IDownloadOperatorFeedbackEvidenceParams {
  operatorFeedbackId: string
  uuid: string
}

interface IDownloadInspectionFileResponse {
  file_path: string
}

export const downloadInspectionFile = async ({
  inspectionId,
  fileName,
}: IDownloadInspectionFile): Promise<IDownloadInspectionFileResponse> => {
  const response = await api().post(`/inspection/${inspectionId}/download_file`, {
    file_name: fileName,
  })

  return response.data
}

export const downloadZipFile = async ({
  reportId,
  dataType,
}: IDownloadZipFile): Promise<IDownloadInspectionFileResponse> => {
  const response = await api().post(`/report_export/${reportId}`, {
    type: 'zip',
    data_type: dataType,
  })
  return response.data
}

export const downloadOperatorFeedbackEvidence = async (
  params: IDownloadOperatorFeedbackEvidenceParams,
): Promise<string> => {
  const response = await api().get(
    `/operator_feedback/${params.operatorFeedbackId}/download_evidence/${params.uuid}`,
  )

  return response.data as string
}

export interface IDeleteOperatorFeedbackEvidenceParams {
  operatorFeedbackId: string
  uuid: string
}

export const deleteOperatorFeedbackEvidence = async (
  params: IDeleteOperatorFeedbackEvidenceParams,
): Promise<IOperatorFeedback> => {
  const response = await api().delete(
    `/operator_feedback/${params.operatorFeedbackId}/${params.uuid}`,
  )

  return response.data as IOperatorFeedback
}
export interface IUploadOperatorFeedbackEvidencesParams {
  operatorFeedbackId: string
  files: File[]

  // Events
  onUploadProgress?: (event: ProgressEvent) => void
}

export const uploadOperatorFeedbackEvidences = async (
  params: IUploadOperatorFeedbackEvidencesParams,
): Promise<IOperatorFeedback> => {
  const formData = new FormData()

  params.files.forEach((file, i) => {
    formData.append(i.toString(), file)
  })

  const response = await api().post(
    `/operator_feedback/${params.operatorFeedbackId}/upload_evidences`,
    formData,
    {
      onUploadProgress: params.onUploadProgress,
    },
  )

  return response.data
}

export interface IAircraftPhotoResponse {
  file_url: string | null
}

export async function getAircraftImageUrl(aircraftId: string): Promise<IAircraftPhotoResponse> {
  const response = await api().get(`/aircraft/${aircraftId}/photo`)

  return response.data as IAircraftPhotoResponse
}

export interface IGetReportPhotoCategoryImage {
  report_id: string
  category_uuid: string
  original?: boolean
}
export async function getReportPhotoCategoryImage({
  report_id,
  category_uuid,
  original,
}: IGetReportPhotoCategoryImage): Promise<string> {
  const response = await api().get(
    `/report/${report_id}/get_photo_category_image/${category_uuid}`,
    {
      params: {
        original,
      },
    },
  )

  return response.data
}

export async function listInspectionInvitations(
  inspection_id: string,
): Promise<IInspectionInvitation[]> {
  const response = await api().get(`/inspection/${inspection_id}/invitations`)

  return response.data
}

export interface IInviteUserToInspectionProps {
  created_by: string
  user_id: string
  level: TInvitationLevel
  inspection_id: string
  expiration_date: string | Date
}

export async function inviteUserToInspection(
  props: IInviteUserToInspectionProps,
): Promise<IInspectionInvitation> {
  console.log(props)
  const response = await api().post(`/inspection/${props.inspection_id}/invite_user`, props)

  return response.data
}

export interface IRevokeInspectionInvitationProps {
  inspection_id: string
  invitation_id: string
}
export async function revokeInspectionInvitation(
  props: IRevokeInspectionInvitationProps,
): Promise<IInspectionInvitation> {
  const response = await api().delete(
    `/inspection/${props.inspection_id}/revoke_invitation/${props.invitation_id}`,
  )

  return response.data
}

export interface IDeleteInspectionInvitationProps {
  inspection_id: string
  invitation_id: string
}
export async function deleteInspectionInvitation(
  props: IRevokeInspectionInvitationProps,
): Promise<IInspectionInvitation> {
  const response = await api().delete(
    `/inspection/${props.inspection_id}/delete_invitation/${props.invitation_id}`,
  )

  return response.data
}

export interface ICheckOutdatedChecklistResponse {
  current_version: number
  outdated: boolean
  reference_version: number
}
export async function checkOutdatedChecklist(
  inspection_id: string,
): Promise<ICheckOutdatedChecklistResponse> {
  const response = await api().get(`/inspection/${inspection_id}/is_checklist_outdated`)

  return response.data
}
