import { FC, ReactNode } from 'react'

import { DialogProps, Icon, SxProps, Tooltip, Typography, useTheme } from '@mui/material'
import * as S from './VinciModal.styles'

type IFormTitle = {
  title?: string
  subtitle: string
}

type VinciModalProps = Omit<DialogProps, 'title'> & {
  open: boolean
  title?: string | IFormTitle
  children: ReactNode
  onClose: () => void
  sx?: SxProps
  contentSx?: SxProps
}

export const VinciModal: FC<VinciModalProps> = ({
  open,
  title,
  onClose,
  children,
  sx,
  contentSx,
  ...props
}) => {
  const theme = useTheme()
  return (
    <S.Wrapper open={open} onClose={onClose} sx={sx} {...props}>
      <Tooltip title="Close">
        <S.CloseIcon onClick={onClose}>
          <Icon>close</Icon>
        </S.CloseIcon>
      </Tooltip>

      {title && (
        <>
          <S.Title sx={{ paddingBottom: 0 }}>
            {typeof title === 'string' && title}
            {typeof title === 'object' && (
              <>
                <Typography variant='h6' component='div' sx={{ color: theme.palette.grey[900] }}>
                  {title.subtitle}
                </Typography>
                <Typography variant='h4' component='span'>
                  {title.title}
                </Typography>
              </>
            )}
          </S.Title>
        </>
      )}
      <S.Content sx={contentSx}>{children}</S.Content>
    </S.Wrapper>
  )
}
